.resource-card {
	width: 300px;
	height: 200px;
	position: relative;
	background-size: cover;
	background-position: center;
	overflow: hidden;
	&:hover {
		cursor: pointer;
	}
}

.resource-info-container {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 40%;
	padding: 0px 15px 5px 15px;
	background-image: linear-gradient(
		to top,
		rgba(255, 255, 255, 0.844) 0%,
		rgba(95, 93, 93, 0) 100%
	);
}

.resource-info {
	padding: 0.3rem 0;
	height: 30%;
	margin: 0;
}

.slick-slide {
	width: 300px;
	height: 200px;
}

.career-body-container {
	margin-top: 50px;
}

.career-slider {
	margin-bottom: 50px;
	/* padding: 10px; */
}

.career-slider-bar {
	margin-top: 14px;
	margin-bottom: 19px;
}

.progress-bar-container {
	width: 100%;
	height: 3px;
	background-color: #e0e0e0;
	border-radius: 10px;
	margin-bottom: 10px;
}

.progress-bar {
	height: 100%;
	background-color: #414af2;
	border-radius: 10px;
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap');
@import-normalize;

:root {
    --main-light-color: #FCFCFC;
    --sub-light-color: whitesmoke;
    --main-dark-color: black;
    --emph-color: #3D3FEB;
}

html {
    font-size: 12px;
}

body {
    background-color: var(--main-light-color);
    color: var(--main-dark-color);
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

a {
    color: inherit;
    text-decoration: none;
}
.align-center {
    align-items: center;
}

.internship-container {
    width: 100vw;
}

.internship-content-container {
    width: 70vw;
    margin-left: auto;
    margin-right: auto;
}

.internship-header-container {
    justify-content: flex-start;
    padding-bottom: 20px;
    margin-top: 50px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.8);
    width: 100%;
}

.internship-count{
    
    background-image: url('../../assets/unispace_gradient.svg');
    background-size: cover;
    height: 32px;
    display: flex;
    width: max-content;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 30px;
    margin-top: 25px;
}

.internship-count a{
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
    color:rgba(0, 0, 0, 0.9)
}

.internship-header-container>h2 {
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.9);
}

.internship-header-container>a {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.8);
}

.internship-grid {
    width: 100%;
    display: grid;
    grid-template-columns: [filter] 250px [internship-content] 1fr;
    column-gap: 20px;
    padding-top: 50px;
}

.internship-filter-col {
    grid-column: filter;
    padding: 0;
    margin: 0;
}

.internship-filter-container {
    border: solid 1px rgba(0, 0, 0, 0.5);
    padding-left: 25px;
    font-size: 16px;
    padding-bottom: 20px;
    padding-top: 3px;
    position: sticky;
    top: 100px;
}

.internship-filter-container a {
    font-weight: 500;
    user-select: none;
    color: rgba(0, 0, 0, 0.8);
}

.filter-option {
    align-items: center;
    margin-top: 10px;
    margin-bottom: 5px;
}

.custom-checkbox-label input[type=checkbox] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    height: 15px;
    width: 15px;
}

.custom-checkbox-label {
    position: relative;
    display: block;
    padding-left: 24px;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 6px;
    margin-top: 10px;
    color: rgba(0, 0, 0, 0.6);
    user-select: none;
}

.custom-checkbox {
    position: absolute;
    top: 1px;
    left: 0;
    height: 15px;
    width: 15px;
    border: solid rgba(0, 0, 0, 0.6) 1px;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox-label input:checked ~.custom-checkbox {
    /* background-color: #3D3FEB; */
    background-image: url('../../assets/checked.svg');
    border: solid #3D3FEB 1px;
}

.custom-checkbox-label.checked {
    /* background-color: #3D3FEB; */
    font-weight: 700;
}

.internship-collapsible-header {
    margin-bottom: 10px;
    margin-top: 25px;
    cursor: pointer;
}

.filter-collapsible-arrow {
    height: 8px;
    margin-right: 10px;
}

.flip-vertical {
    transform: scaleY(-1);
}

.filter-collapsible-div {
    overflow: hidden;
    max-height: 0px;
    display: flex;
    flex-direction: column;
}

.filter-collapsible-div.open {
    max-height: 600px;
}

.internship-content-col {
    grid-column: internship-content;
}

.searchbar {
    height: 42px;
    justify-content: space-between;
    border: solid 1px rgba(0, 0, 0, 0.5);
    width: 100%;
    align-items: center;
    margin-bottom: 30px;
}

input[type="text"] {
    font-size: 14px;
    border-width: 0px;
    background-color: transparent;
    width: 90%;
    padding-left: 10px;
    color: rgba(0, 0, 0, 0.8);
}

input[type="text"]:focus {
    outline: none;
    /* Remove the blue outline */
}

.search-icon {
    height: 24px;
    width: 24px;
    margin-right: 10px;
}

.internship-listing-container {
    width: 100%;
    height: 55px;
    margin: 0;
    padding: 0;
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
}

.internship-listing-grid.with-decoration::after {
    content: '';
    position: absolute;
    bottom: -2px;
    right: -2px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-bottom: 20px solid #3D3FEB;
    /* Set the color you want */
}

.internship-listing-grid {
    display: grid;
    height: 55px;
    width: calc(100% - 20px);
    border: solid 1px rgba(0, 0, 0, 0.5);
    padding-left: 20px;
    grid-template-columns: [company] 1.4fr [position] 2.5fr [location] 1.2fr [post-time] 1fr;
    column-gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.internship-listing-grid>p {
    font-family: sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 400;
    width: 100%;
}

.pagination{
    margin-top: 40px;
    margin-bottom: 100px;
    color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    height: 20px
}

.pagination span{
    cursor: pointer;
    user-select: none;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    margin: 0px 1px 0px 1px;
}

.pagination span.active{
    background-color: #3D3FEB;
    color: rgba(255, 255, 255, 0.9);
    font-weight: 500;
}

.nav-arrow{
    height: 10px;
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
}

.nav-arrow.inactive{
    opacity: 0.4;
    cursor: default;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-800 {
    font-weight: 800 !important;
}

.view-count {
    display: block;
    text-align: center
}

.view-count-num {
    color: #3D3FEB;
}

.textWithEllipsis {
    white-space: nowrap;      
    overflow: hidden;         
    text-overflow: ellipsis; 
  }

@media (max-width: 1300px) {
    .internship-content-container {
        width: 80vw;
    }
}


@media (max-width: 1000px) {
    .internship-content-container {
        width: 90vw;
    }
}

@media (max-width: 800px) {
    .internship-content-container {
        width: 90vw;
    }

    .internship-grid {
        width: 100%;
        display: grid;
        grid-template-columns: [internship-content] 1fr;

        padding-top: 50px;
    }

    .internship-filter-col {
        display: none;
    }

    .custom-checkbox-label {
        margin-top: 2px;
        color: rgba(61, 63, 235, 0.9);
        font-size: 14px;
        padding-left: 28px;
    }

    .custom-checkbox {
        border-color: rgba(61, 63, 235, 0.9);
        height: 14px;
        width: 14px;
    }
}
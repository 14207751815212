.interviewContainer {
    height: '100%';
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-x: auto;
    font-family: 'Inter', sans-serif;
}

.questionsContainer {
    height: auto;
    width: 50vw;
    min-width: 500px;
    margin: 0;
    padding: 0;
}

.highlighted {
    background-color: #DFF2B2;
    padding: 4% 4.5% 4% 4.5%;
    color: var(--main-dark-color);
    opacity: 0.9;
    border-radius: 20px;
}

.questionDialogue {
    height: auto;
    flex-direction: column;
    color: var(--main-dark-color);
    opacity: 0.9;
    margin-bottom: 50px;
    display: flex;
}

.questionTitle {
    flex-direction: row;
    align-items: flex-start;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    line-height: 30px;
    font-size: 17px;
    margin-bottom: 25px;
}

.questionAnswer {
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
}

.questionImage {
    margin: 100px 0 50px;
    height: 20vw;
    background-color: var(--sub-light-color);
    background-size: cover;
}

.blueButton {
    display: 'flex';
    flex-direction: row;
    border-radius: 30px;
    background-color: var(--emph-color);
    padding: 1.8vh 1.7vw 1.8vh 1.7vw;
    align-items: center;
    cursor: pointer;
}

.bottomButtons {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font-weight: bold;
    align-items: center;
    color: var(--main-light-color);
}

.bannerContainer {
    width: 100%;
    height: 80vh;
    background-color: var(--sub-light-color);
    background-size: cover;
}

.gridBannerInfo {
    display: grid;
    width: 60vw;
    height: max-content;
    margin: 40vh auto 10vh;
    grid-template-columns: 3fr 2fr 1fr;
    grid-template-areas:
        "a a ."
        "b b ."
        "c c d";

    align-items: flex-end;
    gap: 15px;
}

.bannerCategory {
    grid-area: a;
    font-weight: 600;
    font-size: 32px;
    color: var(--main-dark-color);
    opacity: 0.6;
}

.bannerCoffeeChatReq {
    grid-area: b;
    font-weight: 300;
    font-size: 14px;
}

.bannerTitle {
    grid-area: c;
    font-weight: 700;
    font-size: 45px;
}

.bannerDate {
    grid-area: d;
    font-weight: 600;
    font-size: 32px;
    color: var(--main-dark-color);
    opacity: 0.6;
    text-align: right;
}

.titleContainer {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    margin-bottom: 60px;
}

.titleLastRow {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.interviewTitle {
    color: var(--main-dark-color);
    opacity: 0.9;
    font-size: 20px;
    font-weight: bolder;
    margin-top: 1vh;
    margin-bottom: 1vh;
}

.interviewCategory {
    color: var(--main-dark-color);
    opacity: 0.8;
    font-size: 15px;
    font-weight: 600;
}
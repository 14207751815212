.mobile-navbar-container {
	display: flex;
	flex-direction: column;
}

.mobile-navbar {
	background-color: aqua;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: #fcfcfc;
	padding: 1.7rem 1.1rem 0.5rem 1.5rem;
}

.mobile-navbar-slogan-logo {
	width: auto;
	height: 4rem;
}

.mobile-navbar-links {
	display: flex;
	align-items: center;
}

.mobile-navbar-links-button {
	background: none;
	border: none;
	font-size: 1.3rem;
	font-weight: 600;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0;
	color: black;
}

.mobile-navbar-links-button-icon {
	margin-left: 0.5rem;
	width: 1.2rem;
}

@import '../../fonts.css';

.container {
    padding: 0vh 10vw 0vh 10vw;
    margin: 0;
    height: 170vh;
}

.gradient {
    background: linear-gradient(to bottom,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.7),
            rgba(255, 255, 255, 1)),
        linear-gradient(to right,
            rgb(235, 253, 222),
            rgb(221, 253, 245),
            rgb(214, 225, 249));
    background-blend-mode: normal, lighten;
    background-size: 100% 100%, 100% 100%;
    background-repeat: no-repeat;
}

.slogan {
    justify-content: center;
    justify-items: center;
    align-items: center;
    width: 100%;
    height: 10vh;
}

.slogan h4 {
    color: var(--main-light-color);
    text-shadow: 0.5px 0.5px 5px rgba(00, 00, 00, 0.3);
    display: inline;
    font-family: 'Inter', sans-serif;
}

.slogan h4 a {
    text-shadow: 0.5px 0.5px 6px rgba(00, 00, 00, 0.5);
    display: inline;
    font-size: larger;
}

.gridContainer {
    display: grid;
    min-height: 40vmin;
    height: max-content;
    width: 100%;
    margin: 0;
    padding: 0;
}

.todayPopularColumns {
    grid-template-columns: 3fr 7fr;
    grid-column-gap: 5%;
}

.col.todaysPick {
    padding: 0;
    height: max-content;
}

.featuredArticle {
    aspect-ratio: 1/1;
    background-color: darkgrey;
    border-radius: 20px;
}

.featuredArticle h3 {
    padding: 40px;
    margin: 0;
    color: var(--main-light-color);
    text-shadow: 0.5px 0.5px 6px rgba(00, 00, 00, 0.5);
}

.col.popular {
    padding: 0;
    height: 100%;
}

.popular .highlight {
    color: var(--emph-color);
}

.flex {
    display: flex;
}

.inlineBlock {
    display: inline-block;
}

.block {
    display: block;
}

.verticalFlex {
    display: flex;
    flex-flow: column;
}

.mainArticles {
    padding-top: 6vh;
    height: 100vh;
    align-items: center;
    flex-direction: column;
}

.popularItemsNav {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 1vh;
}

.popularItemsNavArrow {
    height: 15px;
    margin-left: 2px;
    margin-right: 2px;
    cursor: pointer;
}

.popularItemsEllipseUnfocused {
    height: 5px;
    margin-left: 5px;
    margin-right: 5px
}

.popularItemsEllipseFocused {
    height: 5px;
    margin-left: 5px;
    margin-right: 5px;
}

.popularItemsEllipseFocused circle {
    fill: #495461 !important;
}


.popularItemsContainer {
    flex-direction: row;
    flex-wrap: wrap;
    height: 70%;
    display: flex;
    justify-content: space-between;
}

.popularItem {
    background-color: var(--main-light-color);
    border-radius: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    margin-top: 1%;

}

.popularItemNumber {
    margin: auto;
    height: 90%;
    flex-basis: 10%;
    border-right: 1px solid black;
    align-items: flex-end;
    align-content: center;
    justify-content: center;

}

.popularItemInfo {
    flex-basis: 80%;
    height: 96%;
    flex-direction: column;
    margin-right: 5%;
    margin-top: 5px;
    text-overflow: ellipsis;
    padding: 0;
}

.popularItemNumber>a {
    font-weight: bold;
    font-size: 1.4vw;
}

.popularItemTitle>h3 {
    font-size: 1vw;
    min-width: 0;
    display: inline-block;
    text-align: right;
    margin-top: 0;
}

.popularItemTitle {
    overflow: hidden;
    display: block;
    height: 33%;
    width: 100%;
    padding: 0;
    text-overflow: ellipsis;
    margin-top: 5px;

}

.popularItemInfo>a {
    height: 12%;
    margin-top: 0;
    text-align: right;
    font-size: 1vw;
}

.popularItemLocation {
    align-self: flex-end;
    margin-top: 2px;
    font-size: 1.05vw;
    align-items: center;
}

.mapPin {
    height: 20px;
    margin-right: 8px;
}

.authorImg {
    height: 25px;
    margin-right: 1%;
}

.popularItemsLastRow {
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: auto;
    margin-bottom: 10px;
    justify-content: space-between;
}

.authorName {
    flex-direction: row;
    align-items: center;
    flex-grow: 0.8;
    font-size: 1.2vw;
}

.popularItemReadButton {
    border-radius: 30px;
    border: solid #06D6A0 1px;
    color: #06D6A0;
    padding: 1vh 1vw 1vh 1vw;
}

.popularItemReadButton:hover {
    border-radius: 30px;
    border: solid #06D6A0 1px;
    color: var(--main-light-color);
    background-color: #06D6A0;
    padding: 1vh 1vw 1vh 1vw;
}

.popularItemArrowButton {
    align-self: center;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: center;
    border: solid #06D6A0 1px;
    margin-left: 1vw;
}

.popularItemArrowButton:hover {
    align-self: center;
    border-radius: 50%;
    width: 25px;
    background-color: #06D6A0;
    height: 25px;
    align-items: center;
    justify-content: center;
}

.arrowSvg {
    height: 15px
}

.popularItemArrowButton:hover .arrowSvg {
    height: 30px;
    fill: var(--main-light-color) !important;
}

.popularItemArrowButton:hover .arrowSvg path {
    fill: var(--main-light-color) !important;
}

.contentSpaceSelection {
    border: solid #5E5E5E70 3px;
    border-radius: 30px;
    flex-direction: row;
    width: 45vw;
    height: 6vh;
    background-color: rgab(00, 00, 00, 0.2);
    justify-content: space-between;
    align-items: center;
}

.contentSpaceSelected {
    display: flex;
    border-radius: 30px;
    height: 100%;
    background-color: var(--emph-color);
    border: solid 3px var(--emph-color);
    justify-content: center;
    width: 24%;
    align-items: center;
    font: 'Inter';
    color: var(--main-light-color);
    font-weight: 700;
    cursor: pointer;
    box-shadow: 1px 3px 7px rgba(00, 00, 00, 0.5);
}

.contentSpaceUnselected {
    display: flex;
    border-radius: 30px;
    height: 100%;
    background-color: transparent;
    justify-content: center;
    width: 24%;
    align-items: center;
    font: 'Inter';
    color: #5E5E5E;
    font-weight: 600;
    cursor: pointer
}

.grid-content-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    /* grid-gap: 5%; Optional gap between grid items */
    grid-column-gap: 3%;
    grid-row-gap: 7%;
    width: 100%;
    height: 100%;
    margin-top: 4%;
}

.grid-item {
    background-color: #e3e3e3;
    padding: 10px;
    text-align: center;
    border-radius: 30px;
    padding: 0;
    font-family: 'Inter', sans-serif;
    box-shadow: 0px 3px 5px rgba(00, 00, 00, 0.4);
    cursor: pointer;
}

.contentThumbnail {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    height: 60%;
    background-color: pink;
}

.contentInfo {
    padding-left: 8%;
    padding-right: 8%;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    height: 40%;
    background: linear-gradient(to bottom,
            rgb(247, 254, 255),
            rgb(240, 253, 255),
            rgb(240, 253, 255),
            rgb(213, 235, 251));
}

.contentCategory {
    border-bottom: solid #5E5E5E 0.5px;
    color: #5E5E5E;
    align-items: flex-start;
    padding-top: 5%;
    padding-bottom: 2%;
}

.contentItemTitle {
    margin-top: 0;
    overflow: hidden;
    height: 33%;
    width: 100%;
    padding: 0;
    text-overflow: ellipsis;
    align-items: center;
    justify-content: flex-start;
}

.contentItemLocation {
    margin-top: 2px;
    font-size: 1.05vw;
    align-items: center;
    color: #5E5E5E;
}

.contentIcons {
    height: 15px;
    margin-right: 3px;
}

.popularItemArrowButton:hover .arrowSvg path {
    fill: var(--main-light-color) !important;
}

@media (max-width: 800px) {
    .todaysPick {
        display: none;
    }

    .todayPopularColumns {
        grid-template-columns: 1fr;
        grid-column-gap: 5%;
    }

    .popularItem a {
        font-size: 2vw;
    }

    .popularItemTitle h3 {
        font-size: 2vw;
    }

    .popularItemInfo>a {
        font-size: 2vw;
    }
}
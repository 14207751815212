@import '../../fonts.css';

.navbar-underline {
    width: 99.2vw;
    padding: 0;
    height: 10vh;
    border-bottom: solid rgba(0, 0, 0, 0.4) 1px;
    display: flex;
    justify-content: center;
    background-color: white;
}

.navbar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    height: 7vh;
    width: 70vw;
    margin-top: 3vh;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
}

.fd-row {
    flex-direction: row;
}

.fd-col {
    flex-direction: column;
}

.nav-category-container {
    justify-content: space-between;
    width: 30vw;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 15px;
    height: 100%;
}

.nav-category {
    height: '100%';
    align-items: center;
    border-bottom: solid transparent 4px;
}

.nav-category-selected {
    height: '100%';
    align-items: center;
    border-bottom: solid var(--emph-color) 4px;
    color: var(--emph-color)
}

.logo {
    height: 40px;
    min-width: 20px;
}

nav {
    height: 9vh;
}

nav ul {
    align-items: center;
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    height: 100%;
}

nav li {
    margin: 0 1.5vw;
    justify-content: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-bottom: 3px solid transparent;
}

nav a {
    margin: auto 0;
    font-family: 'Inter', sans-serif;
}

nav .active {
    color: var(--emph-color);
    border-bottom: 3px solid var(--emph-color);
}

.writeSymbol {
    height: 20px;
}

.startButton {
    display: 'flex';
    border-radius: 30px;
    background-color: var(--emph-color);
    padding: 1.6vh 1.5vw 1.6vh 1.5vw;
    font-size: 15px;
}

.languageButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.language {
    stroke: #B2B2B2;
    cursor: pointer;
}

.language.active {
    stroke: #3D3FEB;
}

.startButton>a {
    color: white;
}

/* breakpoint for medium browser width */
@media (max-width: 1100px) {
    .navbar-container {
        width: 80vw;
    }

    .nav-category-container {
        width: 40vw;
    }

}

/* breakpoint for smallest browser width.*/
@media (max-width: 800px) {
    .logo {
        display: none;
    }

    .navbar-container {
        width: 90vw;
    }

    .nav-category-container {
        width: 65vw;
    }

}
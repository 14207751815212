.container {
    height: 800px;
}

.center {
    justify-content: center;
    flex-direction: column;
}

.header {
    flex-direction: row;
    gap: 1.5rem;
    align-items: center;
}

h1 {
    font-size: 2.2rem;
}

p {
    font-size: 20px;
    margin-left: 0.7rem;
}